import { useAuth } from "../contexts/AuthContext";
import { Box, Typography, Avatar } from "@mui/material";
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import GoogleButton from 'react-google-button'
import GithubButton from 'react-github-login-button'
import { profanity } from '@2toad/profanity';
import { useState } from 'react'

import builderCoin_M from '../assets/BuilderCoin_M.png';
import CLogo from '../assets/CLogo.png';
import CSharpLogo from '../assets/CSharpLogo.png';
import JavaLogo from '../assets/JavaLogo.png';
import GoLogo from '../assets/GoLogo.png';
import PythonLogo from '../assets/PythonLogo.png';
import NodeLogo from '../assets/NodeLogo.png';
import RubyLogo from '../assets/RubyLogo.png';
import RustLogo from '../assets/RustLogo.png';
import appleBlackLogo from '../assets/apple-black-logo.png';


export default function ProfilePage() {
    const { linkCreds, linkGoogle, linkApple, linkGitHub, currentUser, changeDisplayName, getUserData } = useAuth();
    const [openLink, setOpenLink] = useState(false);
    const [openName, setOpenName] = useState(false);
    const [emailLinked, setEmailLinked] = useState(false);
    const [googleLinked, setGoogleLinked] = useState(false);
    const [appleLinked, setAppleLinked] = useState(false);
    const [gitHubLinked, setGitHubLinked] = useState(false);
    const [userData, setUserData] = useState();
    const [ranLink, setRanLink] = useState(false)

    //get user data
    useEffect(() => {
        async function getData() {
            const data = await getUserData();
            setUserData(data)
        }
        getData()
    }, [])

    useEffect(() => {
        currentUser.providerData.forEach((provider) => {
            if (provider.providerId === 'google.com') {
                setGoogleLinked(true)
            } else if (provider.providerId === 'apple.com') {
                setAppleLinked(true)
            } else if (provider.providerId === 'github.com') {
                setGitHubLinked(true)
            } else if (provider.providerId === 'password') {
                setEmailLinked(true)
            }
        })
    }, [ranLink])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (data.get('password') === data.get('passwordConfirm')) {
            await linkCreds(data.get('name').trim(), data.get('email'), data.get('password'))
                .then(() => {
                    setRanLink((prev) => !prev)
                    setOpenLink(false)
                    alert("Your account has been linked with the provided email and password.")
                })
        }
        else {
            alert("Passwords must match!");
        }
    };

    const handleSubmitName = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        try {
            if (profanity.exists(data.get('name').trim()))
                throw new Error("Name cannot contain profanity")
            await changeDisplayName(data.get('name').trim()).then(() => {
                setOpenName(false)
            })
        } catch (e) {
            alert('Error changing name: ' + e.message)
        }

    };

    const handleGoogleLink = async () => {
        await linkGoogle().then(() => { setRanLink((prev) => !prev) })
    }

    const handleAppleLink = async () => {
        await linkApple().then(() => { setRanLink((prev) => !prev) })
    }

    const handleGitHubLink = async () => {
        await linkGitHub().then(() => { setRanLink((prev) => !prev) })
    }

    function stringAvatar(name) {
        let trimmedName = name.trim()

        return {
            sx: {
                bgcolor: '#f16522',
                fontSize: '70px',
            },
            children: trimmedName.indexOf(' ') >= 0 ?  `${trimmedName?.split(' ')[0][0]}${trimmedName?.split(' ')[1][0]}` : trimmedName[0],
        };
    }

    return (
        <Box>
            <CssBaseline />
            <Box sx={{ marginTop: 10 }}>
                <Container maxWidth={'sm'} sx={{ m: 'auto', textAlign: 'center' }}>
                    <Card elevation={2} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2, alignItems: 'center' }}>
                        <Avatar style={{ marginTop: '25px', width: '150px', height: '150px', color: 'white', filter: 'drop-shadow(0px 0px 25px rgba(154, 154, 154, 0.50))' }} {...currentUser?.displayName ? stringAvatar(currentUser?.displayName) : null} />
                        <Typography variant="h4" sx={{ mt: 2 }}>{currentUser.displayName ? currentUser?.displayName : currentUser.email}</Typography>
                        <Box sx={{ mb: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
                            <Typography variant="h5" color="primary">{userData?.score}</Typography>
                            <img src={builderCoin_M} alt='Builder Coin' id="builderCoinImg" style={{ marginLeft: '8px' }} />
                        </Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '25px', maxWidth: '90%' }}>
                        {userData?.hasOwnProperty("C-builds") && (
                            <img src={CLogo} alt='C Logo' style={{ marginLeft: '5px', marginRight: '5px', height: '40px' }} />
                        )}
                        {userData?.hasOwnProperty("CSharp-builds") && (
                            <img src={CSharpLogo} alt='C# Logo' style={{ marginLeft: '5px', marginRight: '5px', height: '40px' }} />
                        )}
                        {userData?.hasOwnProperty("Java-builds") && (
                            <img src={JavaLogo} alt='Java Logo' style={{ marginLeft: '4px', marginRight: '4px', height: '40px' }} />
                        )}
                        {userData?.hasOwnProperty("Go-builds") && (
                            <img src={GoLogo} alt='Go Logo' style={{ marginLeft: '3px', marginRight: '3px', height: '40px' }} />
                        )}
                        {userData?.hasOwnProperty("Python-builds") && (
                            <img src={PythonLogo} alt='Python Logo' style={{ marginLeft: '6px', marginRight: '6px', height: '40px' }} />
                        )}
                        {userData?.hasOwnProperty("Node-builds") && (
                            <img src={NodeLogo} alt='Node Logo' style={{ marginLeft: '6px', marginRight: '6px', height: '40px' }} />
                        )}
                        {userData?.hasOwnProperty("Ruby-builds") && (
                            <img src={RubyLogo} alt='Ruby Logo' style={{ marginLeft: '6px', marginRight: '6px', height: '40px' }} />
                        )}
                        {userData?.hasOwnProperty("Rust-builds") && (
                            <img src={RustLogo} alt='Rust Logo' style={{ marginLeft: '6px', marginRight: '6px', height: '43px' }} />
                        )}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', mb: 4 }}>
                            <Button sx={{ width: '25vw' }} onClick={() => setOpenName(true)} variant="contained">Change Display Name</Button>
                            {!emailLinked && <Button sx={{ width: '25vw' }} onClick={() => setOpenLink(true)} variant="contained">Link Email and Password</Button>}
                            {!googleLinked && <GoogleButton style={{
                                width: '25vw',
                                backgroundColor: 'black'
                            }} type="dark" label="Link Account with Google" onClick={() => { handleGoogleLink() }}
                            />}
                            {!appleLinked && <Button
                                    fullWidth
                                    variant="contained"
                                    color='Black'
                                    sx={{ borderRadius: 0, height: 45, textTransform: 'none', }}
                                    onClick={() => { handleAppleLink() }}
                                >
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', 
                                            backgroundColor: 'white', width: 50, height: 45, position: 'absolute', left: 0 
                                }}>
                                    <img src={appleBlackLogo} alt="Apple Logo" style={{ width: 20, height: 25 }} />
                                </Box>
                                <Box sx={{ width: 370, position: 'absolute', right: 0 }}>
                                    <Typography>Link Account with Apple</Typography>
                                </Box>
                            </Button>}
                            {!gitHubLinked && <GithubButton
                                style={{
                                    width: '25vw',
                                }}
                                type='dark'
                                label="Link Account with GitHub"
                                onClick={() => { handleGitHubLink() }}
                            />}
                            {currentUser.providerData.length === 3 && <Typography>All Providers Linked</Typography>}
                        </Box>
                    </Card>
                </Container>
            </Box>
            <Dialog PaperProps={{ sx: { backgroundColor: 'black' } }} open={openLink} onClose={(() => setOpenLink(false))}>
                <DialogTitle>Link Email and Password</DialogTitle>
                <Box
                    sx={{
                        margin: 2,
                        mt: -2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="name"
                            label="Full Name"
                            name="name"
                            autoComplete="name"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="passwordConfirm"
                            label="Confirm Password"
                            type="password"
                            id="passwordConfirm"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Link
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <Dialog maxWidth="sm" fullWidth PaperProps={{ sx: { backgroundColor: 'black' } }} open={openName} onClose={(() => setOpenName(false))}>
                <DialogTitle>Change Display Name</DialogTitle>
                <Box
                    sx={{
                        margin: 2,
                        mt: -2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box component="form" onSubmit={handleSubmitName} sx={{ mt: 2 }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="name"
                            label="Display Name"
                            name="name"
                            defaultValue={currentUser.displayName}
                            autoComplete="name"
                            autoFocus
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Change
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </Box>
    )
}
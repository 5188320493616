import React, { useState, useEffect } from 'react';

import './styles/typewriter.css';

const Typewriter = ({ type, directory, text, textDelay, fontSize, cursor, onTypingEnd }) => {
  
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    if (currentIndex < text.length) {
        setIsTyping(true);

        const timeout = setTimeout(() => {
            setCurrentText(prevText => prevText + text[currentIndex]);
            setCurrentIndex(prevIndex => prevIndex + 1);
        }, textDelay);
  
      return () => clearTimeout(timeout);
    } else {
        setIsTyping(false);

        if(onTypingEnd){
            onTypingEnd();
        }
    }
  }, [currentIndex, textDelay, text]);

  switch(type){
    case 'prompt':
        if (cursor){
            if (isTyping){
                return (
                    <span class="text">
                        <span class="currentPromptTyping" style={{ fontSize: fontSize }}>[bobthebuilder@AuditDeploy {directory}]$&nbsp;<span style={{ fontSize: fontSize }}>{currentText}</span></span>
                    </span>
                );
            } else {
                return (
                    <span class="text">
                        <span class="currentPrompt" style={{ fontSize: fontSize }}>[bobthebuilder@AuditDeploy {directory}]$&nbsp;<span style={{ fontSize: fontSize }}>{currentText}</span></span>
                    </span>
                );
            }
        } else {
            return (
                <span class="text">
                    <span class="prompt" style={{ fontSize: fontSize }}>[bobthebuilder@AuditDeploy {directory}]$&nbsp;<span style={{ fontSize: fontSize }}>{currentText}</span></span>
                </span>
            );
        }
    case 'output':
        return <span style={{ fontSize: fontSize }}>{currentText}</span>;
  }
};

export default Typewriter;
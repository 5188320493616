import React, { useState, useEffect } from 'react';

import useWindowDimensions from '../../components/utils/UseWindowDimensions';
import Typewriter from "../../components/typewriter/typewriter.js";
import './styles/terminalInitOutput.css';

const spinnerArray = ["⣧", "⣇", "⡇", "⠇", "⠃", "⠁", "⠃", "⠇", "⡇", "⣇", "⣧", "⣧"];

const builderPushArray = [
    "2021-12-11 14:55:10.613244 -0500 EST   INFO   builder.go:15:   .builder dir already exists",
    "2021-12-11 14:55:10.614913 -0500 EST   INFO   builder.go:72:   Directories successfully created.",
    "2021-12-11 14:55:10.631572 -0500 EST   INFO   builder.go:78:   Files copied to hidden dir successfully.",
    "2021-12-11 14:55:12.864671 -0500 EST   INFO   derive.go:50:   Go project detected",
    "2021-12-11 14:55:13.282739 -0500 EST   INFO   go.go:95:   running command: /usr/local/Go/bin/go.exe build -v -x -o Builder.exe",
    "2021-12-11 14:55:15.138739 -0500 EST   INFO   createBuilderYaml.go:78:   builder.yaml created ✅",
    "2021-12-11 14:55:20.483441 -0500 EST   INFO   go.go:151:   Go project built successfully.",
    "2021-12-11 14:55:20.907062 -0500 EST   INFO   builder.go:84:   Metadata created successfully.",
    "2021-12-11 14:55:20.912047 -0500 EST   INFO   pushBuildData.go:52:   Metadata+logs data pushed to provided url",
    "2021-12-11 14:55:20.915067 -0500 EST   INFO   builder.go:114:   Hidden Dir is now read-only.",
    "Build Complete 🔨"
];

const builderPushOutputTime = [10, 10, 30, 30, 800, 10, 80, 10, 10, 400];


const TerminalPushOutput = ({ onChange, onDisplayEnd }) => {
    const { height, width } = useWindowDimensions();
    const [showLoadingCursor, setShowLoadingCursor] = useState("hidden");
    const [builderCommands, setBuilderCommands] = useState([]);
    const [currentDir, setCurrentDir] = useState("Builder");
    const [currentTerminalOutput, setCurrentTerminalOutput] = useState([]);
  
    const [timer, setTimer] = useState(null);
    const [time, setTime] = useState(1000);
    const [outputTime, setOutputTime] = useState([]);

    const [showLsOutput, setShowLsOutput] = useState(false);
    const [showLsPrompt, setShowLsPrompt] = useState(false);
    const [showLsPromptCursor, setShowLsPromptCursor] = useState(true);

    const [waitBeforeBuilderLs, setWaitBeforeBuilderLs] = useState(true);

    const [waitBetweenLsAndPushCmd, setWaitBetweenLsAndPushCmd] = useState(false);

    const [showPushPrompt, setShowPushPrompt] = useState(false);
    const [showPushPromptCursor, setShowPushPromptCursor] = useState(true);
    const [showPushClearPrompt, setShowPushClearPrompt] = useState(false);

    const [waitAfterPushCmd, setWaitAfterPushCmd] = useState(false);

    const [waitCount, setWaitCount] = useState(undefined);
    const [waitIcon, setWaitIcon] = useState(undefined);
    const [showSpinner, setShowSpinner] = useState(false);

    const [innerHTML, setInnerHTML] = useState(undefined);

  useEffect(() => {
    // this checks if timer is null OR undefined, if so, return and don't start
    if (timer == null) return;

    // this resets the timer and time when it gets to the end of the array
    if (timer >= currentTerminalOutput.length) {
      setShowSpinner(false);
      setWaitAfterPushCmd(true);
      setTime(1000);
      return;
    }

    const getTime = outputTime[timer];
    setTime(getTime);

    const timeout = increment();

    return () => {
      clearTimeout(timeout);
    };
  }, [timer]);

  function increment() {
    return setTimeout(() => {
      if (currentTerminalOutput === builderCommands){
        setShowSpinner(true);
      }
      setTimer((ele) => ele + 1);
    }, time);
  }

  let terminalOutput = builderCommands.slice(0, timer).map((text, index) => {
    return (
      <div key={index}>
        <p style={{ fontSize: 18 }}>{text}</p>
      </div>
    )
  })

  useEffect(() => {
    if (showLoadingCursor) {
      const toRef = setTimeout(() => {
        setShowLoadingCursor("hidden");
        clearTimeout(toRef);
      }, 1000);
    }
  }, [showLoadingCursor]);

  useEffect(() => {
    if (waitBeforeBuilderLs) {
      const toRef = setTimeout(() => {
        setWaitBeforeBuilderLs(false);
        setShowLsPromptCursor(true);
        setShowLsPrompt(true);
        clearTimeout(toRef);
      }, 10);
    }
  }, [waitBeforeBuilderLs]);

  useEffect(() => {
    if (waitBetweenLsAndPushCmd) {
      const toRef = setTimeout(() => {
        setWaitBetweenLsAndPushCmd(false);
        setShowPushPrompt(true);
        clearTimeout(toRef);
      }, 2500);
    }
  }, [waitBetweenLsAndPushCmd]);

  useEffect(() => {
    if (waitAfterPushCmd) {
      const toRef = setTimeout(() => {
        setWaitAfterPushCmd(false);
        setShowPushClearPrompt(true);
        clearTimeout(toRef);
      }, 2500);
    }
  }, [waitAfterPushCmd]);

  useEffect(() => {
    let currentCount = waitCount
    setTimeout(() => {
      setWaitIcon(spinnerArray[currentCount]);
      if(waitCount === spinnerArray.length - 1){
        setWaitCount(0);
      } else {
        setWaitCount(currentCount + 1);
      }
    }, 100);
  }, [waitCount])

  useEffect(() => {
    if(document.getElementById('pushOutput').innerHTML !== innerHTML){
        onChange();
        setInnerHTML(document.getElementById('pushOutput').innerHTML);
    }
  })

  return (
    <div id="pushOutput">
        {waitBeforeBuilderLs && (
        <span style={{ fontSize: 18 }}>[bobthebuilder@AuditDeploy {currentDir}]$&nbsp;<p class="cursor"></p></span>
        )}
        {showLsPrompt && (
        <Typewriter type="prompt" directory="Builder" text="ls" textDelay={80} fontSize={18} cursor={showLsPromptCursor} onTypingEnd={() => {
            const timeoutRef = setTimeout(() => {
            setShowLsPromptCursor(false);
            setShowLsOutput(true);
            setWaitBetweenLsAndPushCmd(true);
            clearTimeout(timeoutRef);
            }, 1500)
        }}/>
        )}
        {showLsOutput && (
        <div style={{ width: width > 1200 ? "90%" : "99%" }}>
            {width > 1200 && (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div>
                <p style={{ fontSize: 18 }}>artifact/</p>
                <p style={{ fontSize: 18 }}>builder</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>builder.yaml</p>
                <p style={{ fontSize: 18 }}>builder_data/</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>cmd/</p>
                <p style={{ fontSize: 18 }}>compile/</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>derive/</p>
                <p style={{ fontSize: 18 }}>directory/</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>Dockerfile</p>
                <p style={{ fontSize: 18 }}>go.mod</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>go.sum</p>
                <p style={{ fontSize: 18 }}>gui/</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>Jenkinsfile</p>
                <p style={{ fontSize: 18 }}>LICENSE</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>main.go</p>
                <p style={{ fontSize: 18 }}>README.md</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>spinner/</p>
                <p style={{ fontSize: 18 }}>utils/</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>yaml/</p>
                </div>
            </div>
            )}
            {width <= 1200 && (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div>
                <p style={{ fontSize: 18 }}>artifact/</p>
                <p style={{ fontSize: 18 }}>builder/</p>
                <p style={{ fontSize: 18 }}>builder.yaml</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>builder_data/</p>
                <p style={{ fontSize: 18 }}>cmd/</p>
                <p style={{ fontSize: 18 }}>compile/</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>derive/</p>
                <p style={{ fontSize: 18 }}>directory/</p>
                <p style={{ fontSize: 18 }}>Dockerfile</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>go.mod</p>
                <p style={{ fontSize: 18 }}>go.sum</p>
                <p style={{ fontSize: 18 }}>gui/</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>Jenkinsfile</p>
                <p style={{ fontSize: 18 }}>LICENSE</p>
                <p style={{ fontSize: 18 }}>main.go</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>README.md</p>
                <p style={{ fontSize: 18 }}>spinner/</p>
                <p style={{ fontSize: 18 }}>utils/</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>yaml/</p>
                </div>
            </div>
            )}
            {waitBetweenLsAndPushCmd && (
            <span style={{ fontSize: 18 }}>[bobthebuilder@AuditDeploy {currentDir}]$&nbsp;<p class="cursor"></p></span>
            )}
            {showPushPrompt && (
            <div>
                <Typewriter type="prompt" directory="Builder" text="builder -d push https://www.mydomain.com/api/v1/mybuilds/store-build-info" textDelay={80} fontSize={18} cursor={showPushPromptCursor} onTypingEnd={() => {
                const timeoutRef = setTimeout(() => {
                    setShowPushPromptCursor(false);
                    setWaitCount(0);
                    setShowLoadingCursor("visible");
                    const innerTimeoutRef = setTimeout(() => {
                    setBuilderCommands(builderPushArray);
                    setCurrentTerminalOutput(builderPushArray);
                    setOutputTime(builderPushOutputTime);
                    setTimer(0);
                    clearTimeout(innerTimeoutRef);
                    }, 1000)
                    clearTimeout(timeoutRef);
                }, 1000)
                }}/>
                <p class="cursor" style={{ visibility: showLoadingCursor }}></p>
                {terminalOutput}
                {showSpinner && (
                <span id="wait">{waitIcon}</span>
                )}
                {waitAfterPushCmd && (
                <span style={{ fontSize: 18 }}>[bobthebuilder@AuditDeploy {currentDir}]$&nbsp;<p class="cursor"></p></span>
                )}
                {showPushClearPrompt && onDisplayEnd()}
            </div>
            )}
        </div>
        )}
    </div>
  );
};

export default TerminalPushOutput;
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from "../contexts/AuthContext";
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/builder-logo.png';
import GoogleButton from 'react-google-button'
import GithubButton from 'react-github-login-button';
import { profanity } from '@2toad/profanity';

export default function SignupPage() {

  const { signup, currentUser, googleLogin, githubLogin, } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const LoginNav = (e) => {
    e.preventDefault();
    navigate("/login", {state: { prevPath: location.pathname }});
  }

  function isError(obj) {
    return Object.prototype.toString.call(obj) === "[object Error]";
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get('password') === data.get('passwordConfirm')) {
      var fullName = data.get('firstName').trim() + " " + data.get('lastName').trim()
      try {
        if (profanity.exists(fullName))
          throw new Error("Name cannot contain profanity")

        const signUpResponse = await signup(fullName, data.get('email'), data.get('password'))
        if (!isError(signUpResponse)) {
          alert("Your account has been created. You may now Sign In.")
          LoginNav(event);
        } else {
          throw new Error(signUpResponse)
        }
      } catch (e) {
        alert('Error creating account: ' + e.message)
      }
    }
    else {
      alert("Passwords must match!");
    }
  };

  useEffect(() => {
    if (currentUser)
      navigate("/");
  }, [currentUser, navigate])

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={logo} alt='Builder Logo' height={100} />

        <Typography sx={{ mt: 2 }} component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
            <TextField
              margin="normal"
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              autoComplete="firstName"
              autoFocus
              required
            />
            <TextField
              margin="normal"
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lastName"
              required
            />
          </Box>
          {/* <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Display Name"
              name="username"
              autoComplete="username"
              autoFocus
            /> */}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="passwordConfirm"
            label="Confirm Password"
            type="password"
            id="passwordConfirm"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color='primary'
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Box sx={{ alignContent: 'center', display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Typography>Or</Typography>
          </Box>
          <GoogleButton style={{
            width: 'auto',
            marginBottom: 10,
            backgroundColor: 'black'
          }} type="dark" onClick={() => { googleLogin() }}
          >
            Sign Up with Google
          </GoogleButton>

          {/* <GithubButton
            style={{
              width: "auto",
              marginBottom: 10
            }}
            type='dark'
            onClick={() => { githubLogin() }}
          /> */}
          <Grid container>
            <Grid item xs>
              <Link style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }} onClick={LoginNav} href="/login" variant="body2">
                {"Already have an account? Sign In"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
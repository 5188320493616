import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from "../contexts/AuthContext";
import { useNavigate, useLocation } from 'react-router-dom';
import GoogleButton from 'react-google-button'
import GithubButton from 'react-github-login-button'

import { appTheme as theme } from '../styles/theme/theme.js';
import logo from '../assets/builder-logo.png';
import appleBlackLogo from '../assets/apple-black-logo.png';

export default function LoginPage() {
  const { login, googleLogin, appleLogin, githubLogin, currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const prevPath = location.state ? location.state.prevPath : undefined;

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.currentTarget);
      const loginResponse = await login(data.get('email'), data.get('password'))

      if (prevPath === "/signup") {
        navigate("/downloads")
      } else {
        navigate("/builds")
      }
    }
    catch (error) {
      alert(error.message)
    }
  };

  const SignupNav = (e) => {
    e.preventDefault();
    navigate("/signup");
  }

  const ForgotNav = (e) => {
    e.preventDefault();
    navigate("/forgot");
  }

  useEffect(() => {
    if (currentUser)
      navigate("/");
  }, [currentUser, navigate])


  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logo} alt='Builder Logo' height={100} />

          <Typography sx={{mt: 2}} component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Box sx={{alignContent: 'center', display: 'flex', justifyContent: 'center', mb: 2}}>
              <Typography>Or</Typography>
            </Box>

            <GoogleButton style={{
              width: 'auto',
              marginBottom: 10,
              backgroundColor: 'black'
            }} type="dark" onClick={() => { googleLogin() }}
            >
              Sign in with Google
            </GoogleButton>

            <Button
              fullWidth
              variant="contained"
              color='Black'
              sx={{ mb: 1, borderRadius: 0, height: 45, textTransform: 'none', }}
              onClick={() => { appleLogin() }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', 
                          backgroundColor: 'white', width: 50, height: 45, position: 'absolute', left: 0 
              }}>
                <img src={appleBlackLogo} alt="Apple Logo" style={{ width: 20, height: 25 }} />
              </Box>
              <Box sx={{ width: 346, position: 'absolute', right: 0 }}>
                <Typography>Sign in with Apple</Typography>
              </Box>
            </Button>

            <GithubButton
              style={{
                width: "auto",
                marginBottom: 10
              }}
              type='dark'
              onClick={() => { githubLogin() }}
            />

            <Grid container>
              <Grid item xs>
                <Link onClick={ForgotNav} href="/forgot" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link onClick={SignupNav} href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
  );
}
import React, { useContext, useEffect, useState, createContext } from 'react'
import { auth, authCreate } from '../components/utils/firebase'
import { getAdditionalUserInfo, confirmPasswordReset, createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, updateProfile, GithubAuthProvider, EmailAuthProvider, linkWithCredential, linkWithPopup, OAuthProvider } from 'firebase/auth';
import axios from "axios";
const AuthContext = createContext();


export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    async function signup(name, email, password) {
        try {
            const result = await createUserWithEmailAndPassword(authCreate, email, password);

            sendWelcomeEmail(result);

            authCreate.signOut();

            return updateProfile(authCreate.currentUser, { displayName: name })
        } catch (e) {
            return e
        }
    }

    async function login(email, password) {
        try {
            const result = await signInWithEmailAndPassword(auth, email, password)

            sendWelcomeEmail(result);

            return result;
        } catch (e) {
            console.log(e);
        }
    }

    async function googleLogin() {
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);

            sendWelcomeEmail(result);

        } catch (e) {
            console.log(e);
        }
    }

    async function appleLogin() {
        try {
            const provider = new OAuthProvider('apple.com');
            provider.addScope('email');
            provider.addScope('name');
            const result = await signInWithPopup(auth, provider).then((userCred) => {
                if (userCred._tokenResponse.isNewUser === true)
                    updateProfile(auth.currentUser, { displayName: userCred._tokenResponse.screenName })
            });

            sendWelcomeEmail(result);

        } catch (e) {
            console.log(e);
        }
    }

    async function githubLogin() {
        try {
            const provider = new GithubAuthProvider();
            const result = await signInWithPopup(auth, provider).then((userCred) => {
                if (userCred._tokenResponse.isNewUser === true)
                    updateProfile(auth.currentUser, { displayName: userCred._tokenResponse.screenName })
            });

            sendWelcomeEmail(result);

        } catch (e) {
            console.log(e);
        }
    }

    async function changeDisplayName(newDisplayName) {
        try {
            await updateProfile(auth.currentUser, { displayName: newDisplayName })
        } catch (e) {
            console.log(e)
        }
    }

    async function sendWelcomeEmail(result) {
        const additionalUserInfo = getAdditionalUserInfo(result);

        if (additionalUserInfo.isNewUser === true) {
            const token = result.user.idToken

            await fetch(`/api/v1/auth/welcome-email`, {
                method: 'POST',
                headers: {
                    'authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'email': `${result.user.email}`,
                    'uid': `${result.user.uid}`
                })
            }).then((res) => {
                if(res.status !== 201){
                    console.log("Welcome email error: ", res)
                }
            })
        } else {
            // Get user's welcome email status
            axios.get(`/api/v1/auth/checkWelcomeEmailStatus/${result.user.uid}`).then((res) => {
                // If the user's welcome email status exists in arango, handle it
                if(res.data.length > 0){
                    const welcomeEmailSentStatus = res.data[0].sent
                    const milisecondsSinceLastEmailAttempt = new Date() - new Date(res.data[0].dateAttempted)
                    const weekInMiliseconds = 604800000

                    // If it has been less than a week and the user has not received a welcome email, try to send it again
                    if(welcomeEmailSentStatus === false && milisecondsSinceLastEmailAttempt < weekInMiliseconds){
                        const token = result.user.accessToken

                        fetch(`/api/v1/auth/welcome-email`, {
                            method: 'POST',
                            headers: {
                                'authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                'email': `${result.user.email}`,
                                'uid': `${result.user.uid}`
                            })
                        }).then((res) => {
                            if(res.status !== 200){
                                console.log("Welcome email error: ", res)
                            }
                        })
                    }
                }
            })
        }
    }

    function logout() {
        return auth.signOut();
    }

    function forgotPassword(email) {
        return sendPasswordResetEmail(auth, email)
    }

    function resetPassword(oobCode, newPassword) {
        return confirmPasswordReset(auth, oobCode, newPassword)
    }

    async function linkCreds(name, email, password) {
        const credential = EmailAuthProvider.credential(email, password);

        await updateProfile(auth.currentUser, { displayName: name }).then(async () => {
            await linkWithCredential(auth.currentUser, credential).then((usercred) => {
                console.log("Account linking success");
            }).catch((error) => {
                alert("Account linking error: " + error);
            });
        }).catch((error) => alert("Account linking error: " + error))


    }

    async function linkGoogle() {
        const provider = new GoogleAuthProvider();

        await linkWithPopup(auth.currentUser, provider).then((result) => {
            // Accounts successfully linked.
            alert("Your account has been linked with Google.")
        }).catch((error) => {
            alert("Account linking error: " + error);
        });
    }

    async function linkApple() {
        const provider = new OAuthProvider('apple.com');

        await linkWithPopup(auth.currentUser, provider).then((result) => {
            // Accounts successfully linked.
            alert("Your account has been linked with Apple.")
        }).catch((error) => {
            alert("Account linking error: " + error);
        });
    }

    async function linkGitHub() {
        const provider = new GithubAuthProvider();

        await linkWithPopup(auth.currentUser, provider).then((result) => {
            // Accounts successfully linked.
            alert("Your account has been linked with GitHub.")
        }).catch((error) => {
            alert("Account linking error: " + error);
        });
    }

    async function getUserData() {
        var token = await auth.currentUser.getIdToken()
        const res = await axios.get('/api/v1/user/get-user-data', {
            headers: {
                'authorization': `Bearer ${token}`
            }
        })
        return res.data
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })

        return unsubscribe;
    })

    const value = {
        currentUser,
        loading,
        signup,
        login,
        logout,
        forgotPassword,
        resetPassword,
        googleLogin,
        appleLogin,
        githubLogin,
        linkCreds,
        linkGoogle,
        linkApple,
        linkGitHub,
        changeDisplayName,
        getUserData
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}

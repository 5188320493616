import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { CssBaseline } from '@mui/material';

import { useAuth } from '../contexts/AuthContext';
import logo from '../assets/builder-logo.png';
import githubLogoWhite from '../assets/GithubLogoWhite.png';

const pages = ['Home', 'Downloads', 'Builds', 'Feedback'];
const settings = ['Profile', 'Logout'];

function TopAppBarPrivate() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const { logout, currentUser } = useAuth();


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function stringAvatar(name) {
    let trimmedName = name.trim()

    return {
      sx: {
        bgcolor: '#f16522',
      },
      children: trimmedName.indexOf(' ') >= 0 ?  `${trimmedName?.split(' ')[0][0]}${trimmedName?.split(' ')[1][0]}` : trimmedName[0],
    };
  }

  function performUserAction(menuItem) {
    switch (menuItem) {
      case 'Logout':
        logout();
        break;
      case 'Profile':
        navigate('/profile');
        break;
      default:
        console.log(menuItem);
    }

    handleCloseUserMenu();
  }

  function performNavAction(menuItem) {
    switch (menuItem) {
      case 'Home':
        navigate('/');
        break;
      case 'Builds':
        navigate('/builds');
        break;
      case 'Downloads':
        navigate('/downloads');
        break;
      case 'Feedback':
        window.open('https://github.com/AuditDeploy/Builder/discussions/new/choose', '_blank');
        break;
      default:
        console.log(menuItem);
    }

    handleCloseNavMenu();
  }

  return (
    <Box>
      <CssBaseline />
      <AppBar position="sticky" sx={{ backgroundColor: 'black', m: 0, padding: 0 }}>
        <Box>
          <Toolbar disableGutters>
            <Box sx={{ ml: 2, display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center' }} >
              <img style={{ cursor: 'pointer' }} onClick={() => navigate('/')} src={logo} alt='Builder Logo' height={50} />
              <a href="https://github.com/AuditDeploy/Builder" target="_blank" rel="noreferrer" style={{ marginLeft: '15px', marginTop: '4px' }}>
                <img src={githubLogoWhite} alt='Github Logo' width={32} height={32} />
              </a>
            </Box>
            <Box>
              <IconButton
                size="large"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: 'block',
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => { performNavAction(page) }}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ mr: 2 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar style={{ marginRight: "5px", color: 'white' }} {...currentUser?.displayName ? stringAvatar(currentUser?.displayName) : null} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={() => { performUserAction(setting) }}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </Box>

  );
}
export default TopAppBarPrivate;
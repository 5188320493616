import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext';
import TopAppBarPrivate from '../TopAppBarPrivate';
import TopAppBar from '../TopAppBar'

export default function PublicRoutes() {
    const { currentUser } = useAuth();
    return (
        <div>
            {currentUser ? <TopAppBarPrivate /> : <TopAppBar />}
            <Outlet />
        </div>
    )
}
import React, { useState } from 'react';
import { useAuth } from "../contexts/AuthContext";
import { Box, Typography, Card, CardHeader, CardContent, Collapse, IconButton, Button } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import '../styles/DownloadsPage.css';

export default function BuildsPage() {
    const [openWindows, setOpenWindows] = useState(false);
    const [openMacOS, setOpenMacOS] = useState(false);
    const [openLinux, setOpenLinux] = useState(false);
    const { currentUser } = useAuth();


    async function downloadBuilder(os) {
        var token = await currentUser.getIdToken()
        await fetch(`/api/v1/artifacts/get-builder?os=${os}`, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${token}`,
                'Content-Type': os === 'windows' ? 'application/zip' : os === 'mac-intel' ? 'application/octet-stream' : os === 'mac-mseries' ? 'application/octet-stream' : 'application/gzip',
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                var fileName = ''

                if(os === 'windows') {
                    fileName = `builder-windows.zip`;
                } else if(os === 'linux') {
                    fileName = `builder-linux.tar.gz`;
                } else if(os === 'mac-intel') {
                    fileName = `builder-Intel.dmg`;
                } else if(os === 'mac-mseries') {
                    fileName = `builder-MSeries.dmg`;
                }

                const link = document.createElement('a');
                link.href = url;
                link.download = fileName

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            });
    }

    return (
        <Box id="downloadsPageContainer">
            <Typography variant="h2" sx={{ marginTop: '60px' }}>Welcome to Builder</Typography>
            <Typography variant="subtitle1" sx={{ marginTop: '30px' }}>Download the executable and build your project.</Typography>
            <Box id="downloadCardsContainer">
                <Card className="downloadCard">
                    <CardHeader
                        title="Windows"
                        titleTypographyProps={{ variant: 'title' }}
                        className="downloadCardHeader"
                        avatar={
                            <IconButton
                                color="onSurface"
                                aria-label="expand dropdown"
                                size="large"
                                style={{ width: '10px', height: '20px' }}
                                onClick={() => setOpenWindows(!openWindows)}
                            >
                                {openWindows ? <ExpandMoreIcon fontSize="inherit" /> : <NavigateNextIcon fontSize="inherit" />}
                            </IconButton>
                        }
                    />
                    <div className="downloadCardContent">
                        <Collapse in={openWindows} timeout="auto"
                            unmountOnExit>
                            <CardContent>
                                <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                                    Installation instructions:
                                </Typography>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    1. Click ‘Download For Windows’ to download the Builder CLI
                                </Typography>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    2. Place ‘builder.exe’ into a desired path
                                </Typography>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    3. Add path to the Windows ‘Path’ environment variable
                                </Typography>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    4. Open Git Bash and run the following command and choose your login:
                                </Typography>
                                <Box className="downloadCardCodeContainer">
                                    <code>builder login</code>
                                    <IconButton
                                        color="onSurface"
                                        aria-label="copy code"
                                        size="small"
                                        onClick={() => navigator.clipboard.writeText("builder login")}
                                    >
                                        <ContentPasteIcon fontSize='inherit' />
                                    </IconButton>
                                </Box>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    5. Run the following command to complete your first build (this repo is a Node project and requires NPM to build):
                                </Typography>
                                <Box className="downloadCardCodeContainer">
                                    <code>builder init https://github.com/johnpapa/node-hello</code>
                                    <IconButton
                                        color="onSurface"
                                        aria-label="copy code"
                                        size="small"
                                        onClick={() => navigator.clipboard.writeText("builder init https://github.com/johnpapa/node-hello")}
                                    >
                                        <ContentPasteIcon fontSize='inherit' />
                                    </IconButton>
                                </Box>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    6. Finally, run the following command to bring up the Builder help menu:
                                </Typography>
                                <Box className="downloadCardCodeContainer">
                                    <code>builder -h</code>
                                    <IconButton
                                        color="onSurface"
                                        aria-label="copy code"
                                        size="small"
                                        onClick={() => navigator.clipboard.writeText("builder -h")}
                                    >
                                        <ContentPasteIcon fontSize='inherit' />
                                    </IconButton>
                                </Box>
                                <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
                                    <Button variant="contained" color="primary" onClick={() => downloadBuilder('windows')}>Download For Windows</Button>
                                </Box>
                            </CardContent>
                        </Collapse>
                    </div>
                </Card>
                <Card className="downloadCard" sx={{ marginTop: '4px' }}>
                    <CardHeader
                        title="MacOS"
                        titleTypographyProps={{ variant: 'title' }}
                        className="downloadCardHeader"
                        avatar={
                            <IconButton
                                color="onSurface"
                                aria-label="expand dropdown"
                                size="large"
                                style={{ width: '10px', height: '20px' }}
                                onClick={() => setOpenMacOS(!openMacOS)}
                            >
                                {openMacOS ? <ExpandMoreIcon fontSize="inherit" /> : <NavigateNextIcon fontSize="inherit" />}
                            </IconButton>
                        }
                    />
                    <div className="downloadCardContent">
                        <Collapse in={openMacOS} timeout="auto"
                            unmountOnExit>
                            <CardContent>
                                <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                                    Installation instructions:
                                </Typography>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    1. Click ‘Download For Mac’ to download the Builder CLI
                                </Typography>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    2. Double-click 'builder-(processor).dmg' in your downloads folder to mount the file
                                </Typography>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    3. Follow given instructions to install the Builder CLI
                                </Typography>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    4. Open a new terminal and run the following command and choose your login:
                                </Typography>
                                <Box className="downloadCardCodeContainer">
                                    <code>builder login</code>
                                    <IconButton
                                        color="onSurface"
                                        aria-label="copy code"
                                        size="small"
                                        onClick={() => navigator.clipboard.writeText("builder login")}
                                    >
                                        <ContentPasteIcon fontSize='inherit' />
                                    </IconButton>
                                </Box>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    5. Run the following command to complete your first build (this repo is a Node project and requires NPM to build):
                                </Typography>
                                <Box className="downloadCardCodeContainer">
                                    <code>builder init https://github.com/johnpapa/node-hello</code>
                                    <IconButton
                                        color="onSurface"
                                        aria-label="copy code"
                                        size="small"
                                        onClick={() => navigator.clipboard.writeText("builder init https://github.com/johnpapa/node-hello")}
                                    >
                                        <ContentPasteIcon fontSize='inherit' />
                                    </IconButton>
                                </Box>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    6. Finally, run the following command to bring up the Builder help menu:
                                </Typography>
                                <Box className="downloadCardCodeContainer">
                                    <code>builder -h</code>
                                    <IconButton
                                        color="onSurface"
                                        aria-label="copy code"
                                        size="small"
                                        onClick={() => navigator.clipboard.writeText("builder -h")}
                                    >
                                        <ContentPasteIcon fontSize='inherit' />
                                    </IconButton>
                                </Box>
                                <Box sx={{ textAlign: 'center', marginTop: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <Button variant="contained" color="primary" style={{ marginRight: 20 }} onClick={() => downloadBuilder('mac-intel')}>Download For Mac (Intel)</Button>
                                    <Button variant="contained" color="primary" onClick={() => downloadBuilder('mac-mseries')}>Download For Mac (M series)</Button>
                                </Box>
                            </CardContent>
                        </Collapse>
                    </div>
                </Card>
                <Card className="downloadCard" sx={{ marginTop: '4px' }}>
                    <CardHeader
                        title="Linux"
                        titleTypographyProps={{ variant: 'title' }}
                        className="downloadCardHeader"
                        avatar={
                            <IconButton
                                color="onSurface"
                                aria-label="expand dropdown"
                                size="large"
                                style={{ width: '10px', height: '20px' }}
                                onClick={() => setOpenLinux(!openLinux)}
                            >
                                {openLinux ? <ExpandMoreIcon fontSize="inherit" /> : <NavigateNextIcon fontSize="inherit" />}
                            </IconButton>
                        }
                    />
                    <div className="downloadCardContent">
                        <Collapse in={openLinux} timeout="auto"
                            unmountOnExit>
                            <CardContent>
                                <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                                    Installation instructions:
                                </Typography>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    1. Click ‘Download For Linux’ to download the Builder executable
                                </Typography>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    2. Place ‘builder’ into /usr/local/bin or another desired build path
                                </Typography>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    3. Open a new terminal and run the following command and choose your login:
                                </Typography>
                                <Box className="downloadCardCodeContainer">
                                    <code>builder login</code>
                                    <IconButton
                                        color="onSurface"
                                        aria-label="copy code"
                                        size="small"
                                        onClick={() => navigator.clipboard.writeText("builder login")}
                                    >
                                        <ContentPasteIcon fontSize='inherit' />
                                    </IconButton>
                                </Box>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    4. Run the following command to complete your first build (this repo is a Node project and requires NPM to build):
                                </Typography>
                                <Box className="downloadCardCodeContainer">
                                    <code>builder init https://github.com/johnpapa/node-hello</code>
                                    <IconButton
                                        color="onSurface"
                                        aria-label="copy code"
                                        size="small"
                                        onClick={() => navigator.clipboard.writeText("builder init https://github.com/johnpapa/node-hello")}
                                    >
                                        <ContentPasteIcon fontSize='inherit' />
                                    </IconButton>
                                </Box>
                                <Typography variant="body2" ml='3px' mb='3px'>
                                    5. Finally, run the following command to bring up the Builder help menu:
                                </Typography>
                                <Box className="downloadCardCodeContainer">
                                    <code>builder -h</code>
                                    <IconButton
                                        color="onSurface"
                                        aria-label="copy code"
                                        size="small"
                                        onClick={() => navigator.clipboard.writeText("builder -h")}
                                    >
                                        <ContentPasteIcon fontSize='inherit' />
                                    </IconButton>
                                </Box>
                                <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
                                    <Button variant="contained" color="primary" onClick={() => downloadBuilder('linux')}>Download For Linux</Button>
                                </Box>
                            </CardContent>
                        </Collapse>
                    </div>
                </Card>
            </Box>
        </Box>
    )
}
import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext';
import TopAppBarPrivate from '../TopAppBarPrivate';

export default function PrivateRoutes() {
  const { currentUser } = useAuth();
  return (
    currentUser ? <div>
        <TopAppBarPrivate />
        <Outlet />
    </div> : <Navigate to="/" />
  )
}

import { Routes, Route, HashRouter } from "react-router-dom";
import { AuthProvider } from "../../contexts/AuthContext";
import PrivateRoutes from "./PrivateRoutes";
import SignupPage from "../../Pages/SignupPage";
import LoginPage from "../../Pages/LoginPage";
import ForgotPage from '../../Pages/ForgotPage';
import HomePage from '../../Pages/HomePage';
import DownloadsPage from '../../Pages/DownloadsPage';
import BuildsPage from '../../Pages/BuildsPage';
import ProfilePage from "../../Pages/ProfilePage";
import PublicRoutes from "./PublicRoutes";

const RedirectSite = () => {
    window.location.href = "/PrivacyPolicy.html";
    return <></>;
};

function AppRouter() {
    return (
        <AuthProvider>
            <HashRouter>
                <Routes>
                    <Route element={<PublicRoutes />}>
                        <Route index element={<HomePage />}></Route>
                        <Route path="/privacy" element={<RedirectSite />}></Route>
                    </Route>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignupPage />} />
                    <Route path="/forgot" element={<ForgotPage />} />
                    <Route path="/privacy" element={<RedirectSite />}></Route>
                    <Route element={<PrivateRoutes />}>
                        <Route path="/downloads" element={<DownloadsPage />} />
                        <Route path="/profile" element={<ProfilePage />} />
                        <Route path="/builds" element={<BuildsPage />} />
                    </Route>
                </Routes>
            </HashRouter>
        </AuthProvider>
    )
}

export default AppRouter
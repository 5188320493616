import React, { useState, useEffect } from 'react';

import useWindowDimensions from '../../components/utils/UseWindowDimensions';
import Typewriter from "../../components/typewriter/typewriter.js";
import './styles/terminalInitOutput.css';

const TerminalHelpOutput = ({ onChange, onDisplayEnd }) => {
    const { height, width } = useWindowDimensions();
    const [showLoadingCursor, setShowLoadingCursor] = useState("hidden");
    const [currentDir, setCurrentDir] = useState("Builder");

    const [showHelpOutput, setShowHelpOutput] = useState(false);
    const [showHelpPrompt, setShowHelpPrompt] = useState(false);
    const [showHelpPromptCursor, setShowHelpPromptCursor] = useState(true);

    const [waitBeforeBuilderHelp, setWaitBeforeBuilderHelp] = useState(true);

    const [showHelpClearPrompt, setShowHelpClearPrompt] = useState(false);

    const [waitAfterHelpCmd, setWaitAfterHelpCmd] = useState(false);

    const [innerHTML, setInnerHTML] = useState(undefined);


  useEffect(() => {
    if (showLoadingCursor) {
      const toRef = setTimeout(() => {
        setShowLoadingCursor("hidden");
        clearTimeout(toRef);
      }, 1000);
    }
  }, [showLoadingCursor]);

  useEffect(() => {
    if (waitBeforeBuilderHelp) {
      const toRef = setTimeout(() => {
        setWaitBeforeBuilderHelp(false);
        setShowHelpPromptCursor(true);
        setShowHelpPrompt(true);
        clearTimeout(toRef);
      }, 10);
    }
  }, [waitBeforeBuilderHelp]);

  useEffect(() => {
    if (waitAfterHelpCmd) {
      const toRef = setTimeout(() => {
        setShowHelpClearPrompt(true);
        clearTimeout(toRef);
      }, 1500);
    }
  }, [waitAfterHelpCmd]);

  useEffect(() => {
    if(document.getElementById('helpOutput').innerHTML !== innerHTML){
        onChange();
        setInnerHTML(document.getElementById('helpOutput').innerHTML);
    }
  })

  return (
    <div id="helpOutput">
        {waitBeforeBuilderHelp && (
        <span style={{ fontSize: 18 }}>[bobthebuilder@AuditDeploy {currentDir}]$&nbsp;<p class="cursor"></p></span>
        )}
        {showHelpPrompt && (
        <Typewriter type="prompt" directory="Builder" text="builder --help" textDelay={80} fontSize={18} cursor={showHelpPromptCursor} onTypingEnd={() => {
            const timeoutRef = setTimeout(() => {
            setShowHelpPromptCursor(false);
            setShowHelpOutput(true);
            setWaitAfterHelpCmd(true);
            clearTimeout(timeoutRef);
            }, 1500)
        }}/>
        )}
        {showHelpOutput && (
        <div style={{ width: width > 1200 ? "90%" : "99%" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                               🔨 BUILDER 🔨</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                                             </p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                                #%&&&%  ,&&</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                             ##. #&&&&&&&&& &&&&&</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                               .&&&#        &&&&/</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                               .&&&%         &&&&</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                               .&&&#        &&&&,</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                               .&&&% &&&&&&&&&&</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                               .&&&# ......,#&&&&%</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                               .&&&#           &&&&</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                               .&&&#           #&&&.</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                               .&&&#          %&&&#</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                               .&&&% &&&&&&&&&&&&.</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                               .&&&% &&&&&&&#,</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}> </p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                                  Commands</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}> </p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* builder login: login to builder account (should be run before doing any builds)</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* builder init: auto-build a project that doesn't have a builder yaml (repo needed)</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - ex: builder init &lt;repo&gt; &lt;flags&gt;</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* builder config: build project w/ a builder.yaml (repo needed)</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - ex: builder config &lt;repo&gt; &lt;flags&gt;</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* builder docker [optional flags]: generate docker image</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - optional flag: '--release' or '-r' pushes to a remote registry and saves it for future builds</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>      - ex: builder docker --release registry.gitlab.com</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* builder push [optional flags]: pushes build metadata and logs JSON to provided url (url needed in line or in builder.yaml)</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - optional flag: '--save' to automate "push" process for future builds</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - ex: builder push &lt;optional_flags&gt; &lt;url&gt;</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* builder: build project w/ builder.yaml while in the projects directory (no repo needed) </p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - ex: builder &lt;flags&gt;</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* builder gui: display the Builder GUI (requires Chrome for use)</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}> </p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                                   Flags</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}> </p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* '--help' or '-h': provide info for Builder</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* '--output' or '-o': user defined output path for artifact</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* '--name' or '-n': user defined project name</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* '--branch' or '-b': specify repo branch</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* '--debug' or '-d': show Builder log output</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* '--verbose' or '-v': show log output for project being built</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* '--hidden' or '-H': hide build directories during build</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* '--compress' or '-z' or '-C': compress built artifact</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}> </p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>                            builder.yaml params</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}> </p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* projectname: provide name for project</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - ("helloworld", etc)</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* projectpath: provide path for project to be built</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - ("/Users/Name/Projects", etc)</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* projecttype: provide language/framework being used</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - ("Node", "Java", "Go", "Rust", "Python", "C#", "Ruby")</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* buildsdir: provide name of folder to store builder build data</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - ("builder_data", "Builds", etc)</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* buildtool: provide tool used to install dependencies/build project</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - ("maven", "npm", "bundler", "pipenv", etc)</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* buildfile: provide file name needed to install dep/build project</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - Can be any user specified file. ("myCoolProject.go", "package.json" etc)</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* prebuildcmd: for C/C++ and npm projects only. Provide command to run before configcmd and buildcmd</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - ("autoreconf -vfi", "./autogen.sh", etc)</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* configcmd: for C/C++ projects only. provide full command to configure C/C++ project before running buildcmd</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - ("./configure")</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* buildcmd: provide full command to build/compile project</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - ("npm install --silent", "mvn -o package", anything not provided by the Builder as a default)</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* artifactlist: provide comma seperated list of artifact names as string</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - ("artifact", "artifact.exe", "artifact.rpm,artifact2.rpm,artifact3.rpm", etc)</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* outputpath: provide path for artifact to be sent</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - ("/Users/Name/Artifacts", etc)</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* repobranch: specify repo branch name</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - (“feature/“new-branch”)</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* docker: generate docker image</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - dockerfile: name of dockerfile</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - registry: registry to push docker image to</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - version: tag to give docker image</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>* push:</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - url: url to push build metadata and logs JSON to</p>
                <p style={{ fontSize: 18, whiteSpace: "pre" }}>   - auto: (true/false) whether to automate pushing process for future builds</p>
                </div>
            </div>
            {waitAfterHelpCmd && (
            <span style={{ fontSize: 18 }}>[bobthebuilder@AuditDeploy {currentDir}]$&nbsp;<p class="cursor"></p></span>
            )}
            {showHelpClearPrompt && onDisplayEnd()}
        </div>
        )}
    </div>
  );
};

export default TerminalHelpOutput;
import { useNavigate } from "react-router-dom";
import { CssBaseline, AppBar, Box, Toolbar, Button } from "@mui/material";

import logo from '../assets/builder-logo.png';
import githubLogoWhite from '../assets/GithubLogoWhite.png';

function TopAppBar() {
  const navigate = useNavigate();

  return (
    <Box>
      <CssBaseline />
      <AppBar position="sticky" sx={{ backgroundColor: 'black', m: 0, padding: 0 }}>
        <Box>
          <Toolbar disableGutters>
            <Box sx={{ ml: 2, display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center' }} >
              <img src={logo} alt='Builder Logo' height={50} />
              <a href="https://github.com/AuditDeploy/Builder" target="_blank" rel="noreferrer" style={{ marginLeft: '15px', marginTop: '4px' }}>
                <img src={githubLogoWhite} alt='Github Logo' width={32} height={32} />
              </a>
            </Box>
            <Box sx={{ mr: 2, display: 'flex', flexDirection: 'row' }}>
              <Button variant="outlined" color="primary" onClick={() => navigate('/login')}>Login</Button>
              <Button variant="contained" color="primary" onClick={() => navigate('/signup')} sx={{ ml: 1 }}>Sign Up</Button>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </Box>

  );
}
export default TopAppBar;
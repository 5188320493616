import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button } from "@mui/material";

import '../styles/HomePage.css';
import { useAuth } from "../contexts/AuthContext";
import useWindowDimensions from '../components/utils/UseWindowDimensions';
import builderLogo from '../assets/builder-logo.png';
import builderIcon from '../assets/builderIcon.png';
import ReplayIcon from '@mui/icons-material/Replay';
import showBuildPage from '../assets/ShowBuildPage.PNG';
import builderLeftPhone from '../assets/BuilderLeftPhone.png';
import builderRightPhone from '../assets/BuilderRightPhone.png';
import windowsLogo from '../assets/windowsLogo.png';
import macOSLogo from '../assets/macOSLogo.png';
import linuxLogo from '../assets/linuxLogo.png';
import iOSLogo from '../assets/iOSLogo.png';
import androidLogo from '../assets/androidLogo.png';
import githubLogo from '../assets/GithubLogo.png';
import twitterXLogo from '../assets/TwitterXLogo.png';
import Typewriter from "../components/typewriter/typewriter.js";
import TerminalInitOutput from "../components/terminalOutput/terminalInitOutput";
import TerminalDockerOutput from "../components/terminalOutput/terminalDockerOutput";
import TerminalPushOutput from "../components/terminalOutput/terminalPushOutput";
import TerminalHelpOutput from "../components/terminalOutput/terminalHelpOutput";


export default function HomePage() {
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const { currentUser } = useAuth();

  const [showRepeatIcon, setShowRepeatIcon] = useState(false);

  const [showBuilderInitCmd, setShowBuilderInitCmd] = useState(true);
  const [showBuilderDockerCmd, setShowBuilderDockerCmd] = useState(false);
  const [showBuilderPushCmd, setShowBuilderPushCmd] = useState(false);
  const [showBuilderHelpCmd, setShowBuilderHelpCmd] = useState(false);

  const [terminalScroll, setTerminalScroll] = useState(undefined);
  const [userScrolledUp, setUserScrolledUp] = useState(false);

  const [changed, setChanged] = useState(0);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const handleTerminalScroll = (event) => {
    var terminal = document.getElementById('terminal');

    if (event.currentTarget.scrollTop < terminalScroll){
      if (terminalScroll - event.currentTarget.scrollTop >= 10){
        setUserScrolledUp(true);
      }
    }

    if (userScrolledUp && (event.currentTarget.scrollHeight - event.currentTarget.scrollTop === event.currentTarget.clientHeight)){
      setUserScrolledUp(false);
    }
    
    setTerminalScroll(event.currentTarget.scrollTop);
  }

  useEffect(() => {
    if(!userScrolledUp){
      var terminal = document.getElementById('terminal');
      terminal.scrollTop = terminal.scrollHeight;
    }
  }, [terminalScroll, changed])


  return (
    <Box id="container">
      <Box style={{ width: width, marginTop: 20 }}>
      <div id="terminal-window">
        <header style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <img src={builderIcon} alt='Builder Icon' height={21} />
          {showRepeatIcon && (
            <ReplayIcon fontSize="small" sx={{ color: 'white', mr: 1.5 }} onClick={() => {
              setShowBuilderHelpCmd(false);
              setShowBuilderPushCmd(false);
              setShowBuilderDockerCmd(false);
              setShowBuilderInitCmd(false);
              setChanged(0);
              setTimeout(() => {
                setShowBuilderInitCmd(true);
                setUserScrolledUp(false);
              }, 10)
            }} />
          )}
        </header>
        <section id="terminal" onScroll={handleTerminalScroll}>
          {showBuilderInitCmd && (
            <TerminalInitOutput onChange={() => setChanged(changed + 1)} onDisplayEnd={() => setShowBuilderDockerCmd(true)} />
          )}
          {showBuilderDockerCmd && (
            <TerminalDockerOutput onChange={() => setChanged(changed + 1)} onDisplayEnd={() => setShowBuilderPushCmd(true)} />
          )}
          {showBuilderPushCmd && (
            <TerminalPushOutput onChange={() => setChanged(changed + 1)} onDisplayEnd={() => setShowBuilderHelpCmd(true)} />
          )}
          {showBuilderHelpCmd && (
            <TerminalHelpOutput onChange={() => setChanged(changed + 1)} onDisplayEnd={() => setShowRepeatIcon(true)} />
          )}
        </section>
      </div>
      </Box>
      <Box id="getStartedHeaderContainer">
        <img src={builderLogo} alt='Builder Logo' height={100} />
        <p class="builderSubHeader" style={{ marginTop: "20px", textAlign: 'center' }}>Build Anywhere, Anytime: Desktop to Mobile</p>
        <p class="builderText" style={{ marginTop: "40px", marginBottom: "40px", width: width * 0.60 }}>Discover Builder: More than just a build automation tool, it’s a revolution in seamless, cross-platform development efficiency.
         With logs and metadata for each build, you’re always in the know, on any platform – Windows, Mac, Linux, Android, or iOS. Embrace our ‘Never Not Know Again’ motto for streamlined distributed software 
         development. Builder – simple, powerful, indispensable. Build anywhere, anytime, and always pick up right where you left off.</p>
        <Button id="getStartedButton" variant="contained" color="primary" onClick={() => currentUser ? navigate('/downloads') : navigate('/signup')}>Get Started</Button>
      </Box>
      <Box style={{ display: "flex", justifyContent: "center", marginTop: 80 }}>
        <Box id="whyBuilderContainer" style={{ width: width * 0.90 }}>
          <p class="builderSubHeader" style={{ alignSelf: 'flex-start', marginBottom: 20 }}>Why Builder?</p>
          <img src={showBuildPage} alt='Builder Metadata' width="100%" height="100%" />
          <p class="builderSubHeader" style={{ alignSelf: 'flex-start', marginTop: 20, marginBottom: 20 }}>Never Not Know Again</p>
          <p class="builderText" style={{ marginBottom: 20, textAlign: 'left', width: '100%' }}>The only build tool that allows you to pass in a repo and build your project in a single command, all while getting extensive metadata 
          tied to your shippable product.  There is no need to frantically track down build info if something goes wrong.  Know who built it, in what directory, on what machine, at what time.  Every time.</p>
        </Box>
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <Box id="builderMobileContainer">
          <img src={builderLeftPhone} alt='Builder App' />
          <p class="builderSubHeader" style={{ alignSelf: 'center', textAlign: 'center' }}>Take Your Builds With You on the Go</p>
          <img src={builderRightPhone} alt='Builder App' />
        </Box>
      </Box>
      <Box id="getStartedFooterContainer">
        <p class="header">Start building with Builder</p>
        <Box marginBottom="50px">
          <p class="subHeader">Join the revolution where master builders emerge and transform the future!</p>
        </Box>
        <Button id="getStartedButton" variant="contained" color="primary" onClick={() => currentUser ? navigate('/downloads') : navigate('/signup')}>Get Started</Button>
        <Box id="availablePlatformsContainer">
          <Box className="platformContainer" sx={{ marginRight: '40px' }} onClick={() => currentUser ? navigate('/downloads') : navigate('/signup')}>
            <img src={windowsLogo} alt='Windows Logo' width={32} height={32} />
            <Box className="platformTextContainer">
              <p className="platformText1">Available on:</p>
              <p className="platformText2">Windows</p>
            </Box>
          </Box>
          <Box className="platformContainer" onClick={() => currentUser ? navigate('/downloads') : navigate('/signup')}>
            <img src={macOSLogo} alt='MacOS Logo' width={45} height={45} />
            <Box className="platformTextContainer">
              <p className="platformText1">Available on:</p>
              <p className="platformText2">Mac OS</p>
            </Box>
          </Box>
          <Box className="platformContainer" sx={{ marginLeft: '40px' }} onClick={() => currentUser ? navigate('/downloads') : navigate('/signup')}>
            <img src={linuxLogo} alt='Linux Logo' width={42} height={42} />
            <Box className="platformTextContainer">
              <p className="platformText1">Available on:</p>
              <p className="platformText2">Linux</p>
            </Box>
          </Box>
        </Box>
        <Box sx={{ margin: '50px auto 0 auto' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
            <Box className="platformContainer" onClick={() => openInNewTab("https://apps.apple.com/us/app/builder-ai-code-builder/id6471438184?platform=iphone")}>
              <img src={iOSLogo} alt='iOS Logo' width={35} height={35} />
              <Box className="platformTextContainer">
                <p className="platformText1">Available on:</p>
                <p className="platformText2">iOS</p>
              </Box>
            </Box>
            <Box className="platformContainer" sx={{ marginLeft: '40px' }} onClick={() => openInNewTab("https://play.google.com/store/apps/details?id=com.auditdeploy.builder&hl=en_US&gl=US")}>
              <img src={androidLogo} alt='Android Logo' width={32} height={38} />
              <Box className="platformTextContainer">
                <p className="platformText1">Available on:</p>
                <p className="platformText2">Android</p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box id="footerContainer" >
        <p class="footerText">
          Experience the Future of Software Development. ©2024 Builder powered by <a id="builderProject" href="https://github.com/AuditDeploy/Builder" target="_blank" rel="noreferrer">Next-Gen Builder Open Source Project</a>. Brought to You by 
          <a id="auditDeploy" href="https://www.auditdeploy.com/" target="_blank" rel="noreferrer"> AuditDeploy Inc</a>. All Rights Reserved.
        </p>
        <Box id="leftFooter">
          <Box id="socialIcons">
            <a href="https://github.com/AuditDeploy/Builder" target="_blank" rel="noreferrer">
              <img src={githubLogo} alt='Github Logo' width={32} height={32} />
            </a>
            <a href="https://twitter.com/TheBuilderTool" target="_blank" rel="noreferrer">
              <img src={twitterXLogo} alt='Twitter X Logo' width={32} height={32} />
            </a>
          </Box>
          <p id="privacyText" onClick={() => navigate("/privacy")}>Privacy</p>
        </Box>
      </Box>
    </Box>
  )
}

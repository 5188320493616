import { ThemeProvider } from '@mui/material/styles';
import TagManager from 'react-gtm-module'

import AppRouter from './components/utils/AppRouter'
import { appTheme } from './styles/theme/theme.js'
import './App.css';

// Initialize Google Tag Manager
const tagManagerArgs = {
  gtmId: 'GTM-N6MZTXRG'
}
TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <ThemeProvider theme={appTheme}>
      <div>
        <AppRouter/>
      </div>
    </ThemeProvider>
  )
}

export default App;

import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#212121',
      paper: '#282828'
    },
    primary: {
      main: "#F16522",
    },
    onSurface: {
      main: "#D3D3D3",
    },
    Black: {
      main: '#000000',
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          sx: { "&.Mui-focused": { color: "white" } },
        }
      }
    }
  },

});
import React, { useState, useEffect } from 'react';

import useWindowDimensions from '../../components/utils/UseWindowDimensions';
import Typewriter from "../../components/typewriter/typewriter.js";
import './styles/terminalInitOutput.css';

const spinnerArray = ["⣧", "⣇", "⡇", "⠇", "⠃", "⠁", "⠃", "⠇", "⡇", "⣇", "⣧", "⣧"];

const builderDockerArray = [
    "2021-12-11 14:54:37.613244 -0500 EST   INFO   builder.go:15:   .builder dir already exists",
    "2021-12-11 14:54:37.614913 -0500 EST   INFO   docker.go:113:   Directories successfully created.",
    "2021-12-11 14:54:37.631572 -0500 EST   INFO   docker.go:141:   running command: /bin/sh -c sudo docker build -t Builder_1702503505 .",
    "2021-12-11 14:54:40.864671 -0500 EST   INFO   docker.go:210:   retrieving build info from container...",
    "2021-12-11 14:54:41.282739 -0500 EST   INFO   docker.go:279:   retrieving build logs from container...",
    "2021-12-11 14:54:43.138739 -0500 EST   INFO   docker.go:401:   Tagging and pushing docker image...",
    "2021-12-11 14:54:59.483441 -0500 EST   INFO   docker.go:451:   Docker image successfully tagged and pushed to provided registry.",
    "2021-12-11 14:54:59.907062 -0500 EST   INFO   docker.go:479:   Metadata saved successfully.",
    "2021-12-11 14:54:59.912047 -0500 EST   INFO   createBuilderYaml.go:74:   builder.yaml created ✅",
    "2021-12-11 14:54:59.915067 -0500 EST   INFO   docker.go:492:   Build metadata and logs posted to server.",
    "Build Complete 🔨"
];

const builderDockerOutputTime = [10, 10, 30, 2000, 500, 10, 500, 10, 10, 400];


const TerminalDockerOutput = ({ onChange, onDisplayEnd }) => {
    const { height, width } = useWindowDimensions();
    const [showPromptCursor, setShowPromptCursor] = useState(true);
    const [showLoadingCursor, setShowLoadingCursor] = useState("hidden");
    const [builderCommands, setBuilderCommands] = useState([]);
    const [currentDir, setCurrentDir] = useState("Builder");
    const [currentTerminalOutput, setCurrentTerminalOutput] = useState([]);
  
    const [timer, setTimer] = useState(null);
    const [time, setTime] = useState(1000);
    const [outputTime, setOutputTime] = useState([]);

    const [showLsOutput, setShowLsOutput] = useState(false);
    const [showLsPrompt, setShowLsPrompt] = useState(false);
    const [showLsPromptCursor, setShowLsPromptCursor] = useState(true);

    const [waitBeforeBuilderLs, setWaitBeforeBuilderLs] = useState(true);

    const [waitBetweenLsAndDockerCmd, setWaitBetweenLsAndDockerCmd] = useState(false);

    const [showDockerPrompt, setShowDockerPrompt] = useState(false);
    const [showDockerPromptCursor, setShowDockerPromptCursor] = useState(true);
    const [showDockerClearPrompt, setShowDockerClearPrompt] = useState(false);

    const [waitAfterDockerCmd, setWaitAfterDockerCmd] = useState(false);

    const [waitCount, setWaitCount] = useState(undefined);
    const [waitIcon, setWaitIcon] = useState(undefined);
    const [showSpinner, setShowSpinner] = useState(false);

    const [innerHTML, setInnerHTML] = useState(undefined);

  useEffect(() => {
    // this checks if timer is null OR undefined, if so, return and don't start
    if (timer == null) return;

    // this resets the timer and time when it gets to the end of the array
    if (timer >= currentTerminalOutput.length) {
      setShowSpinner(false);
      setWaitAfterDockerCmd(true);
      setTime(1000);
      return;
    }

    const getTime = outputTime[timer];
    setTime(getTime);

    const timeout = increment();

    return () => {
      clearTimeout(timeout);
    };
  }, [timer]);

  function increment() {
    return setTimeout(() => {
      if (currentTerminalOutput === builderCommands){
        setShowSpinner(true);
      }
      setTimer((ele) => ele + 1);
    }, time);
  }

  let terminalOutput = builderCommands.slice(0, timer).map((text, index) => {
    return (
      <div key={index}>
        <p style={{ fontSize: 18 }}>{text}</p>
      </div>
    )
  })

  useEffect(() => {
    if (showLoadingCursor) {
      const toRef = setTimeout(() => {
        setShowLoadingCursor("hidden");
        clearTimeout(toRef);
      }, 1000);
    }
  }, [showLoadingCursor]);

  useEffect(() => {
    if (waitBeforeBuilderLs) {
      const toRef = setTimeout(() => {
        setWaitBeforeBuilderLs(false);
        setShowLsPromptCursor(true);
        setShowLsPrompt(true);
        clearTimeout(toRef);
      }, 10);
    }
  }, [waitBeforeBuilderLs]);

  useEffect(() => {
    if (waitBetweenLsAndDockerCmd) {
      const toRef = setTimeout(() => {
        setWaitBetweenLsAndDockerCmd(false);
        setShowDockerPrompt(true);
        clearTimeout(toRef);
      }, 2500);
    }
  }, [waitBetweenLsAndDockerCmd]);

  useEffect(() => {
    if (waitAfterDockerCmd) {
      const toRef = setTimeout(() => {
        setWaitAfterDockerCmd(false);
        setShowDockerClearPrompt(true);
        clearTimeout(toRef);
      }, 2500);
    }
  }, [waitAfterDockerCmd]);

  useEffect(() => {
    let currentCount = waitCount
    setTimeout(() => {
      setWaitIcon(spinnerArray[currentCount]);
      if(waitCount === spinnerArray.length - 1){
        setWaitCount(0);
      } else {
        setWaitCount(currentCount + 1);
      }
    }, 100);
  }, [waitCount])

  useEffect(() => {
    if(document.getElementById('dockerOutput').innerHTML !== innerHTML){
        onChange();
        setInnerHTML(document.getElementById('dockerOutput').innerHTML);
    }
  })

  return (
    <div id="dockerOutput">
        {waitBeforeBuilderLs && (
        <span style={{ fontSize: 18 }}>[bobthebuilder@AuditDeploy {currentDir}]$&nbsp;<p class="cursor"></p></span>
        )}
        {showLsPrompt && (
        <Typewriter type="prompt" directory="Builder" text="ls" textDelay={80} fontSize={18} cursor={showLsPromptCursor} onTypingEnd={() => {
            const timeoutRef = setTimeout(() => {
            setShowLsPromptCursor(false);
            setShowLsOutput(true);
            setWaitBetweenLsAndDockerCmd(true);
            clearTimeout(timeoutRef);
            }, 1500)
        }}/>
        )}
        {showLsOutput && (
        <div style={{ width: width > 1200 ? "90%" : "99%" }}>
            {width > 1200 && (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div>
                <p style={{ fontSize: 18 }}>artifact/</p>
                <p style={{ fontSize: 18 }}>builder</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>builder.yaml</p>
                <p style={{ fontSize: 18 }}>cmd/</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>compile/</p>
                <p style={{ fontSize: 18 }}>derive/</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>directory/</p>
                <p style={{ fontSize: 18 }}>Dockerfile</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>go.mod</p>
                <p style={{ fontSize: 18 }}>go.sum</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>gui/</p>
                <p style={{ fontSize: 18 }}>Jenkinsfile</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>LICENSE</p>
                <p style={{ fontSize: 18 }}>main.go</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>README.md</p>
                <p style={{ fontSize: 18 }}>spinner/</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>utils/</p>
                <p style={{ fontSize: 18 }}>yaml/</p>
                </div>
            </div>
            )}
            {width <= 1200 && (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div>
                <p style={{ fontSize: 18 }}>artifact/</p>
                <p style={{ fontSize: 18 }}>builder/</p>
                <p style={{ fontSize: 18 }}>builder.yaml</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>builder_data/</p>
                <p style={{ fontSize: 18 }}>cmd/</p>
                <p style={{ fontSize: 18 }}>compile/</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>derive/</p>
                <p style={{ fontSize: 18 }}>directory/</p>
                <p style={{ fontSize: 18 }}>Dockerfile</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>go.mod</p>
                <p style={{ fontSize: 18 }}>go.sum</p>
                <p style={{ fontSize: 18 }}>gui/</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>Jenkinsfile</p>
                <p style={{ fontSize: 18 }}>LICENSE</p>
                <p style={{ fontSize: 18 }}>main.go</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>README.md</p>
                <p style={{ fontSize: 18 }}>spinner/</p>
                <p style={{ fontSize: 18 }}>utils/</p>
                </div>
                <div>
                <p style={{ fontSize: 18 }}>yaml/</p>
                </div>
            </div>
            )}
            {waitBetweenLsAndDockerCmd && (
            <span style={{ fontSize: 18 }}>[bobthebuilder@AuditDeploy {currentDir}]$&nbsp;<p class="cursor"></p></span>
            )}
            {showDockerPrompt && (
            <div>
                <Typewriter type="prompt" directory="Builder" text="builder docker -d" textDelay={80} fontSize={18} cursor={showDockerPromptCursor} onTypingEnd={() => {
                const timeoutRef = setTimeout(() => {
                    setShowDockerPromptCursor(false);
                    setWaitCount(0);
                    setShowLoadingCursor("visible");
                    const innerTimeoutRef = setTimeout(() => {
                    setBuilderCommands(builderDockerArray);
                    setCurrentTerminalOutput(builderDockerArray);
                    setOutputTime(builderDockerOutputTime);
                    setTimer(0);
                    clearTimeout(innerTimeoutRef);
                    }, 1000)
                    clearTimeout(timeoutRef);
                }, 1000)
                }}/>
                <p class="cursor" style={{ visibility: showLoadingCursor }}></p>
                {terminalOutput}
                {showSpinner && (
                <span id="wait">{waitIcon}</span>
                )}
                {waitAfterDockerCmd && (
                <span style={{ fontSize: 18 }}>[bobthebuilder@AuditDeploy {currentDir}]$&nbsp;<p class="cursor"></p></span>
                )}
                {showDockerClearPrompt && onDisplayEnd()}
                {/* {showDockerClearPrompt && (
                <Typewriter type="prompt" directory="Builder" text="clear" textDelay={80} fontSize={18} cursor={showDockerClearPromptCursor} onTypingEnd={() => {
                    const timeoutRef = setTimeout(() => {
                    //setShowBuilderDockerCmd(false);
                    //setShowBuilderPushCmd(true);
                    setShowDockerClearPrompt(false);
                    setShowLsPrompt(false);
                    setShowLsOutput(false);
                    setShowDockerPrompt(false);
                    setWaitBeforeBuilderLs(true);
                    setShowDockerPromptCursor(true);

                    onDisplayEnd();

                    setTimer(null);
                    clearTimeout(timeoutRef);
                    }, 1000)
                }}/>
                )} */}
            </div>
            )}
        </div>
        )}
    </div>
  );
};

export default TerminalDockerOutput;
import React from 'react'
import { useEffect, useState } from "react";
import { Box, Card, CardContent, Button, CssBaseline, TextField, Typography, Container, Tooltip, CircularProgress, IconButton, DialogContent } from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import { Link, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import FolderIcon from '@mui/icons-material/Folder';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { useTheme } from '@mui/material/styles';
import axios from "axios";
import "../styles/BuildsPage.css"
import { useAuth } from "../contexts/AuthContext";
import goLogo from '../assets/GoLogo.png'
import javaLogo from '../assets/JavaLogo.png'
import cLogo from '../assets/CLogo.png'
import cppLogo from '../assets/C++Logo.png'
import cSharpLogo from '../assets/CSharpLogo.png'
import nodeLogo from '../assets/NodeLogo.png'
import pythonLogo from '../assets/PythonLogo.png'
import rubyLogo from '../assets/RubyLogo.png'
import rustLogo from '../assets/RustLogo.png'

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
  Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
  TableHead: TableHead,
  TableRow: React.forwardRef((props, ref) => <TableRow hover {...props} ref={ref} />),
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
}

const TableComponentsEmpty = {
  Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
  Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
  TableHead: TableHead,
  TableRow: React.forwardRef((props, ref) => <TableRow {...props} ref={ref} />),
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
}

export default function BuildsPage() {
  const theme = useTheme();
  const { currentUser } = useAuth();
  const [buildData, setBuildData] = useState([]);
  const [logsData, setLogsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedBuild, setSelectedBuild] = useState({});
  const [selectedBuildLogs, setSelectedBuildLogs] = useState(undefined);
  const [fullscreenLogs, setFullscreenLogs] = useState(false);
  const [searchLogsValue, setSearchLogsValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRefresh((prev) => !prev)
  }, [])

  useEffect(() => {
    async function getBuilds() {
      var token = await currentUser.getIdToken()
      axios.get('/api/v1/builds/get-builds', {
        headers: {
          'authorization': `Bearer ${token}`
        }
      }).then((res) => {
        setBuildData(res.data.sort((a, b) => {
          var firstSplit = a.StartTime.split(" ")
          var secondSplit = b.StartTime.split(" ")
          firstSplit.shift()
          secondSplit.shift()

          return new Date(secondSplit.join(' ')) - new Date(firstSplit.join(' '));
        }))
        setFilteredData(res.data.sort((a, b) => {
          var firstSplit = a.StartTime.split(" ")
          var secondSplit = b.StartTime.split(" ")
          firstSplit.shift()
          secondSplit.shift()

          return new Date(secondSplit.join(' ')) - new Date(firstSplit.join(' '));
        }))
      }).catch((e) => console.log(e))
    }

    getBuilds();
  }, [refresh])

  useEffect(() => {
    async function getLog() {
      setLoading(true)
      var token = await currentUser.getIdToken()
      await axios.get(`/api/v1/builds/get-log/key/${selectedBuild._key}`, {
        headers: {
          'authorization': `Bearer ${token}`
        }
      }).then((res) => {
        // Add index to logs so we can get line numbers
        let jsonArray = JSON.parse(res.data.logs)
        for (let logIndex in jsonArray){
          let index = parseInt(logIndex) + 1 // Add 1 so we don't start at 0

          if (index <= 9) {
            index = "  " + String(index)
          } else {
            index = String(index)
          }

          jsonArray[logIndex].index = index 
        }

        setSelectedBuildLogs(jsonArray)
        setLoading(false)
      })
    }
    try {
      if (Object.keys(selectedBuild).length !== 0) {
        getLog();
      }
    } catch (e) {
      console.log('Error Getting Log: ' + e)
    }

  }, [selectedBuild])

  useEffect(() => { // search for builds
    const upperCaseSearchValue = searchValue.toLocaleUpperCase()
    if (searchValue !== "") {
      var tempArr = buildData.filter((build) => build?.ArtifactName?.toLocaleUpperCase().includes(upperCaseSearchValue) || 
                                                build?.ProjectName?.toLocaleUpperCase().includes(upperCaseSearchValue) || 
                                                build?.ProjectType.toLocaleUpperCase().includes(upperCaseSearchValue) ||
                                                build?.UserName.toLocaleUpperCase().includes(upperCaseSearchValue) ||
                                                String(build?.MasterGitHash.slice(0,7)).toLocaleUpperCase().includes(upperCaseSearchValue) ||
                                                String(build?.BuildID).toLocaleUpperCase().includes(upperCaseSearchValue))
      setFilteredData(tempArr)
    } else {
      setFilteredData(buildData)
    }
  }, [searchValue])

  useEffect(() => { // search for logs
    const upperCaseSearchValue = searchLogsValue.toLocaleUpperCase()

    //unHighlight();

    if (searchLogsValue !== "") {
      highlight(upperCaseSearchValue)
    } else {
      unHighlight();
    }

  }, [searchLogsValue])

  const handleRowClick = (build) => {
    setSelectedBuild(build)
    setOpen(true)
  }

  function getLogo(projectType) {
    switch (projectType?.toLowerCase()) {
      case "c":
        return <img style={{ height: '40px', width: '60px', objectFit: 'contain', marginBottom: -10, marginTop: -10, }} src={cLogo} alt="C" />
      case "c++":
        return <img style={{ height: '40px', width: '60px', objectFit: 'contain', marginBottom: -10, marginTop: -10, }} src={cppLogo} alt="C++" />
      case "c#":
      case "csharp":
        return <img style={{ height: '40px', width: '60px', objectFit: 'contain', marginBottom: -10, marginTop: -10 }} src={cSharpLogo} alt="CSharp" />
      case "go":
        return <img style={{ height: '40px', width: '60px', objectFit: 'contain', marginBottom: -10, marginTop: -10 }} src={goLogo} alt="GoLang" />
      case "java":
        return <img style={{ height: '40px', width: '60px', objectFit: 'contain', marginBottom: -10, marginTop: -10 }} src={javaLogo} alt="Java" />
      case "node":
        return <img style={{ height: '40px', width: '60px', objectFit: 'contain', marginBottom: -10, marginTop: -10 }} src={nodeLogo} alt="Node" />
      case "python":
        return <img style={{ height: '40px', width: '60px', objectFit: 'contain', marginBottom: -10, marginTop: -10 }} src={pythonLogo} alt="Python" />
      case "ruby":
        return <img style={{ height: '40px', width: '60px', objectFit: 'contain', marginBottom: -10, marginTop: -10 }} src={rubyLogo} alt="Ruby" />
      case "rust":
        return <img style={{ height: '45px', width: '60px', objectFit: 'contain', marginBottom: -12, marginTop: -8 }} src={rustLogo} alt="Rust" />
      default:
        return <></>
    }
  }

  function highlight(text) {
    let logElements = document.getElementsByClassName("logMsg");

    // First, unhighlight what we have already highlighted
    unHighlight()

    let indexOfFirstHighlightedElement = 0;
    let indexOfFirstFound = false
    for (let logIndex in selectedBuildLogs) {
      let innerText = selectedBuildLogs[logIndex].msg;
      if (innerText !== undefined){
        let index = innerText.toLocaleUpperCase().indexOf(text);
        if (index >= 0) {
          if (!indexOfFirstFound){
            indexOfFirstHighlightedElement = logIndex;
            indexOfFirstFound = true
          } 

          innerText = innerText.substring(0,index) + "<span class='highlight'>" + innerText.substring(index,index+text.length) + "</span>" + innerText.substring(index + text.length);
          logElements[logIndex].innerHTML = innerText;
        }
      }
    }

    // After highlight, scroll to first highlighted element
    document.getElementsByClassName("logLines")[indexOfFirstHighlightedElement].scrollIntoView();
  }

  function unHighlight() {
    let logElements = document.getElementsByClassName('logMsg');

    // Scroll back to top of logs
    window.onload = () => {
      document.getElementById("logDiv").scrollTop = 0;
    }

    for (let logIndex in selectedBuildLogs) {
      if (logElements[logIndex] !== undefined){
        logElements[logIndex].innerHTML = selectedBuildLogs[logIndex].msg;
      }
    }
  }

  function displayArtifacts(artifactName) {
    let artifactArray = artifactName?.split(",");

    return (artifactArray?.map((artifact, index) => {
      if (index !== artifactArray.length - 1){
        return (
          <Box sx={{ backgroundColor: '#3F3F3F', height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', borderBottom: 2, borderBottomColor: theme.palette.background.paper }}>
            <Typography>{artifact}</Typography>
          </Box>
        )
      } else {
        return (
          <Box sx={{ backgroundColor: '#3F3F3F', height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography>{artifact}</Typography>
          </Box>
        )
      }
    }))
  }

  return (
    <Box>
      <CssBaseline />
      <Box sx={{ textAlign: 'left', mt: 5 }}>
        <Container maxWidth={'lg'} sx={{ m: 'auto' }}>
          <Card elevation={3} sx={{ mb: 3, mt: -2, width: '100%', borderRadius: 5, height: '100%' }}>
            <CardContent sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
              <TextField size="small" label='Search' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} sx={{ ml: 1, mr: 1, width: '100%' }} />
            </CardContent>
          </Card>
          <TableVirtuoso
            style={{ height: '75vh' }}
            data={filteredData.length > 0 ? filteredData : ['']}
            components={filteredData.length > 0 ? TableComponents : TableComponentsEmpty}
            fixedHeaderContent={() => (
              <TableRow sx={{ backgroundColor: 'black' }}>
                <TableCell style={{ width: 85 }} />
                <TableCell style={{}}>
                  Project
                </TableCell>
                <TableCell style={{}}>
                  Artifact
                </TableCell>
                <TableCell style={{}}>
                  Time
                </TableCell>
                <TableCell style={{}}>
                  User
                </TableCell>
                <TableCell style={{}}>
                  Hash
                </TableCell>
                <TableCell style={{}}>
                  Build ID
                </TableCell>
              </TableRow>
            )}
            itemContent={(index, build) => {
              if (build?.ProjectName) {
                return (
                  <>
                    <TableCell onClick={() => handleRowClick(build)} style={{}}>
                      {getLogo(build?.ProjectType)}
                    </TableCell>
                    <TableCell onClick={() => handleRowClick(build)} style={{}}>
                      {build?.ProjectName}
                    </TableCell>
                    <TableCell onClick={() => handleRowClick(build)} style={{}}>
                      {build?.ArtifactName}
                    </TableCell>
                    <TableCell onClick={() => handleRowClick(build)} style={{}}>
                      {build?.StartTime}
                    </TableCell>
                    <TableCell onClick={() => handleRowClick(build)} style={{}}>
                      {build?.UserName}
                    </TableCell>
                    <TableCell onClick={() => handleRowClick(build)} style={{}}>
                      {build?.MasterGitHash.slice(0,7)}
                    </TableCell>
                    <TableCell onClick={() => handleRowClick(build)} style={{}}>
                      {build?.BuildID}
                    </TableCell>
                  </>
                )
              } else {
                return (
                  <>
                    <TableCell style={{ textAlign: 'center' }} colSpan={7}>
                      <p>No results yet!</p>
                      <p><Link to={`/downloads`} style={{ color: '#F16522', textDecoration: 'none' }}>Download the Builder binary</Link> and try building your first project using the Builder repository at: https://github.com/AuditDeploy/Builder</p>
                    </TableCell>
                  </>
                )
              }

            }
            }
          />
          <Button variant="contained" sx={{ mt: 1 }} onClick={() => { setRefresh((prev) => !prev) }}>Refresh</Button>
        </Container>
      </Box>
      {selectedBuild && !fullscreenLogs && <Dialog PaperProps={{ sx: { backgroundColor: 'black' } }} maxWidth={'xl'} fullWidth onClose={() => {setOpen(false); setSelectedBuildLogs(undefined)}} open={open}>
        <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" sx={{ color: '#f16522', fontWeight: 'bold' }}>{selectedBuild.ProjectName}</Typography>
          <Typography variant="body1">{selectedBuild.StartTime}</Typography>
        </DialogTitle>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <List sx={{ width: '45%', ml: 5 }}>
            <ListItem divider sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#f16522', fontWeight: 'bold' }}>Project Name: </Typography>
              <Typography>{selectedBuild.ProjectName}</Typography>
            </ListItem>
            <ListItem divider sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#f16522', fontWeight: 'bold' }}>Project Type: </Typography>
              <Typography>{selectedBuild.ProjectType}</Typography>
            </ListItem>
            <ListItem divider sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#f16522', fontWeight: 'bold' }}>Username: </Typography>
              <Typography>{selectedBuild.UserName}</Typography>
            </ListItem>
            <ListItem divider sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#f16522', fontWeight: 'bold' }}>Home Directory: </Typography>
              <Typography>{selectedBuild.HomeDir}</Typography>
            </ListItem>
            <ListItem divider sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#f16522', fontWeight: 'bold' }}>IP Address: </Typography>
              <Typography>{selectedBuild.IP}</Typography>
            </ListItem>
            <ListItem divider sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#f16522', fontWeight: 'bold' }}>Git URL: </Typography>
              <a id="gitURLLink" href={selectedBuild.GitURL} target="_blank" rel="noreferrer">
                <Typography>{selectedBuild.GitURL}</Typography>
              </a>
            </ListItem>
            <ListItem divider sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#f16522', fontWeight: 'bold' }}>Git Hash: </Typography>
              <Typography>{selectedBuild.MasterGitHash}</Typography>
            </ListItem>
            <ListItem divider sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#f16522', fontWeight: 'bold' }}>Branch Name: </Typography>
              <Typography>{selectedBuild.BranchName}</Typography>
            </ListItem>
            <ListItem sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#f16522', fontWeight: 'bold' }}>Build ID: </Typography>
              <Typography>{selectedBuild.BuildID}</Typography>
            </ListItem>
          </List>
          <Box sx={{ width: '35%', mr: 5, textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ backgroundColor: 'black', height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography>Artifact</Typography>
            </Box>
            <Box sx={{ maxHeight: 310, overflowY: 'auto' }}>
            {displayArtifacts(selectedBuild.ArtifactName)}
            </Box>
            <Tooltip title={selectedBuild.ArtifactLocation}>
              <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: 'black', height: 30, alignItems: 'center' }}>
                <FolderIcon fontSize="small" sx={{ color: 'grey', mr: 0.5, ml: 0.5 }} />
                <Typography noWrap variant="caption" sx={{ width: '94%' }} >{selectedBuild.ArtifactLocation}</Typography>
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ height: '35%', m: 5, mt: 2, mb: 2, width: '92%', alignSelf: 'center' }}>
            <Box sx={{backgroundColor: 'black', height: '35px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: '2px' }}>
              <Box sx={{ width: '25px' }}></Box>
              <p>Build Log</p>
              <IconButton children={<OpenInFullIcon fontSize="small" />} sx={{ marginRight: '10px' }} onClick={() => {setFullscreenLogs(true); setLogsData(selectedBuildLogs);}}/>
            </Box>
            {loading ? <>
            <Box sx={{ height: '35vh', overflowY: 'scroll', overflowX: 'scroll', backgroundColor: 'black', fontSize: '14px', padding: 1, display: 'flex' }}>
              <CircularProgress sx={{ m: 'auto', color: '#f16522' }} />
            </Box>
          </> :
            <>
              {selectedBuildLogs && <Box sx={{ height: '35vh', overflowY: 'scroll', overflowX: 'scroll', backgroundColor: 'black', fontSize: '14px', padding: 1 }}>
                {selectedBuildLogs.map((log, index) =>
                  <pre key={index} style={{ display: 'flex', flexDirection: 'row', margin: 5 }}>
                    <Typography sx={{ color: 'lightgreen', fontSize: '14px' }}>{`[${new Date(log.timestamp).toLocaleString()}] - `}</Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: log.level === 'info' ? 'lightblue' : log.level === 'warn' ? 'yellow' : 'red'
                      }}>
                      {`${log.level.toUpperCase()}: `}
                    </Typography>
                    {log.msg}
                  </pre>
                )}
              </Box>}
            </>
          }
        </Box>
      </Dialog>}
      {fullscreenLogs && <Dialog PaperProps={{ sx: { backgroundColor: 'black' } }} maxWidth={'xl'} fullWidth onClose={() => {setFullscreenLogs(false); setSearchLogsValue("");}} open={fullscreenLogs}>
        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: '98%', height: '98%' }}>
          <TextField size="small" label='Search' value={searchLogsValue} onChange={(e) => setSearchLogsValue(e.target.value)} sx={{ mb: '2px', width: '100%', backgroundColor: 'black' }} />
            <Box id="logDiv" sx={{ height: '75vh', overflowY: 'scroll', backgroundColor: 'black', fontSize: '14px', padding: 1 }}>
                {selectedBuildLogs.map((log, index) =>
                  <pre key={index} className="logLines" style={{ display: 'flex', flexDirection: 'row', margin: 5 }}>
                    <Typography sx={{ color: 'orange', fontSize: '14px', marginRight: '10px' }}>{log.index}</Typography>
                    <Typography sx={{ color: 'lightgreen', fontSize: '14px' }}>{`[${new Date(log.timestamp).toLocaleString()}] - `}</Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: log.level === 'info' ? 'lightblue' : log.level === 'warn' ? 'yellow' : 'red'
                      }}>
                      {`${log.level.toUpperCase()}: `}
                    </Typography>
                    <p className="logMsg" style={{ whiteSpace: 'pre-wrap' }}>
                      {log.msg.trim()}
                    </p>
                  </pre>
                )}
              </Box>
          </Box>
        </DialogContent>
      </Dialog>}
    </Box>
  )
}

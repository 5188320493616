// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyBMu53glTR6OCpSYFbw_zjvw4v_hg-uUhU",
  authDomain: "builder-5a9cd.firebaseapp.com",
  projectId: "builder-5a9cd",
  storageBucket: "builder-5a9cd.appspot.com",
  messagingSenderId: "2082809382",
  appId: "1:2082809382:web:5d6cc2782d15285b1928e7",
  measurementId: "G-MEPYF8TV68"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const appCreate = initializeApp(firebaseConfig, "secondary");
export const auth = getAuth(app);
export const authCreate = getAuth(appCreate);

import React, { useState, useEffect } from 'react';

import Typewriter from "../../components/typewriter/typewriter.js";
import './styles/terminalInitOutput.css';

const spinnerArray = ["⣧", "⣇", "⡇", "⠇", "⠃", "⠁", "⠃", "⠇", "⡇", "⣇", "⣧", "⣧"];

const builderInitArray = [
  "2021-12-11 14:54:32.565927 -0500 EST   INFO   cloneRepo.go:55:   git clone https://github.com/AuditDeploy/Builder.git",
  "2021-12-11 14:54:32.585572 -0500 EST   INFO   init.go:23:   Repo cloned succcessfully.",
  "2021-12-11 14:54:32.586024 -0500 EST   INFO   builder.go:15:   .builder dir already exists",
  "2021-12-11 14:54:32.586045 -0500 EST   INFO   init.go:27:   Directories successfully created.",
  "2021-12-11 14:54:32.640000 -0500 EST   INFO   derive.go:50:   Go project detected",
  "2021-12-11 14:54:32.640466 -0500 EST   INFO   go.go:93:   running command: /usr/local/go/bin/go build -v -x -o Builder",
  "2021-12-11 14:54:33.555078 -0500 EST   INFO   createBuilderYaml.go:74:   builder.yaml created ✅",
  "2021-12-11 14:54:33.587224 -0500 EST   INFO   go.go:149:   Go project built successfully.",
  "2021-12-11 14:54:33.587245 -0500 EST   INFO   init.go:39:   Metadata created successfully.",
  "2021-12-11 14:54:33.587496 -0500 EST   INFO   init.go:49:   Hidden Dir is now read-only.",
  "2021-12-11 14:54:34.408914 -0500 EST   INFO   init.go:53:   Build metadata and logs posted to server.",
  "Build Complete 🔨"
];

const builderInitOutputTime = [10, 10, 200, 200, 800, 10, 800, 10, 10, 10, 400];


const TerminalInitOutput = ({ onChange, onDisplayEnd }) => {
    const [showPromptCursor, setShowPromptCursor] = useState(true);
    const [showLoadingCursor, setShowLoadingCursor] = useState("hidden");
    const [builderCommands, setBuilderCommands] = useState([]);
    const [currentDir, setCurrentDir] = useState("builds");
    const [currentTerminalOutput, setCurrentTerminalOutput] = useState([]);
  
    const [timer, setTimer] = useState(null);
    const [time, setTime] = useState(1000);
    const [outputTime, setOutputTime] = useState([]);

    const [waitBetweenInitAndLs, setWaitBetweenInitAndLs] = useState(false);

    const [showLsOutput, setShowLsOutput] = useState(false);
    const [showLsPrompt, setShowLsPrompt] = useState(false);
    const [showLsPromptCursor, setShowLsPromptCursor] = useState(true);
  
    const [waitBetweenLsAndCd, setWaitBetweenLsAndCd] = useState(false);
    const [showCdPrompt, setShowCdPrompt] = useState(false);
    const [showCdPromptCursor, setShowCdPromptCursor] = useState(true);
  
    const [waitBetweenDemos, setWaitBetweenDemos] = useState(false);
    const [showClearPrompt, setShowClearPrompt] = useState(false);

    const [waitCount, setWaitCount] = useState(undefined);
    const [waitIcon, setWaitIcon] = useState(undefined);
    const [showSpinner, setShowSpinner] = useState(false);

    const [innerHTML, setInnerHTML] = useState(undefined);

  useEffect(() => {
    // this checks if timer is null OR undefined, if so, return and don't start
    if (timer == null) return;

    // this resets the timer and time when it gets to the end of the array
    if (timer >= currentTerminalOutput.length) {
      setShowSpinner(false);
      setWaitBetweenInitAndLs(true);
      setTime(1000);
      return;
    }

    const getTime = outputTime[timer];
    setTime(getTime);

    const timeout = increment();

    return () => {
      clearTimeout(timeout);
    };
  }, [timer]);

  function increment() {
    return setTimeout(() => {
      if (currentTerminalOutput === builderCommands){
        setShowSpinner(true);
      }
      setTimer((ele) => ele + 1);
    }, time);
  }

  let terminalOutput = builderCommands.slice(0, timer).map((text, index) => {
    return (
      <div key={index}>
        <p style={{ fontSize: 18 }}>{text}</p>
      </div>
    )
  })

  useEffect(() => {
    if (showLoadingCursor) {
      const toRef = setTimeout(() => {
        setShowLoadingCursor("hidden");
        clearTimeout(toRef);
      }, 1000);
    }
  }, [showLoadingCursor]);

  useEffect(() => {
    if (waitBetweenInitAndLs) {
      const toRef = setTimeout(() => {
        setWaitBetweenInitAndLs(false);
        setShowLsPrompt(true);
        clearTimeout(toRef);
      }, 2500);
    }
  }, [waitBetweenInitAndLs]);

  useEffect(() => {
    if (waitBetweenLsAndCd) {
      const toRef = setTimeout(() => {
        setWaitBetweenLsAndCd(false);
        setShowCdPrompt(true);
        clearTimeout(toRef);
      }, 2500);
    }
  }, [waitBetweenLsAndCd]);

  useEffect(() => {
    if (waitBetweenDemos) {
      const toRef = setTimeout(() => {
        setWaitBetweenDemos(false);
        setShowClearPrompt(true);
        clearTimeout(toRef);
      }, 2500);
    }
  }, [waitBetweenDemos]);

  useEffect(() => {
    let currentCount = waitCount
    setTimeout(() => {
      setWaitIcon(spinnerArray[currentCount]);
      if(waitCount === spinnerArray.length - 1){
        setWaitCount(0);
      } else {
        setWaitCount(currentCount + 1);
      }
    }, 100);
  }, [waitCount])

  useEffect(() => {
    if(document.getElementById('initOutput').innerHTML !== innerHTML){
        onChange();
        setInnerHTML(document.getElementById('initOutput').innerHTML);
    }
  })

  return (
    <div id="initOutput">
        <Typewriter type="prompt" directory="builds" text="builder init https://github.com/AuditDeploy/Builder.git -d" textDelay={80} fontSize={18} cursor={showPromptCursor} onTypingEnd={() => {
            setTimeout(() => {
                setWaitCount(0);
                setShowPromptCursor(false);
                setShowLoadingCursor("visible");
                const timeoutRef = setTimeout(() => {
                setBuilderCommands(builderInitArray);
                setCurrentTerminalOutput(builderInitArray);
                setOutputTime(builderInitOutputTime);
                setTimer(0)
                clearTimeout(timeoutRef);
                }, 1000)
            }, 1000)
            }} />  
            <p class="cursor" style={{ visibility: showLoadingCursor }}></p>
            {terminalOutput}
            {showSpinner && (
            <span id="wait">{waitIcon}</span>
            )}
            {waitBetweenInitAndLs && (
            <span style={{ fontSize: 18 }}>[bobthebuilder@AuditDeploy {currentDir}]$&nbsp;<p class="cursor"></p></span>
            )}
            {showLsPrompt && (
            <Typewriter type="prompt" directory="builds" text="ls" textDelay={80} fontSize={18} cursor={showLsPromptCursor} onTypingEnd={() => {
                const timeoutRef = setTimeout(() => {
                setShowLsPromptCursor(false);
                setShowLsOutput(true);
                setWaitBetweenLsAndCd(true);
                clearTimeout(timeoutRef);
                }, 1000)
            }}/>
            )}
            {showLsOutput && (
            <div>
                <p style={{ fontSize: 18 }}>Builder/</p>
                {waitBetweenLsAndCd && (
                <span style={{ fontSize: 18 }}>[bobthebuilder@AuditDeploy {currentDir}]$&nbsp;<p class="cursor"></p></span>
                )}
                {showCdPrompt && (
                <Typewriter type="prompt" directory="builds" text="cd Builder/" textDelay={80} fontSize={18} cursor={showCdPromptCursor} onTypingEnd={() => {
                    const timeoutRef = setTimeout(() => {
                    setShowCdPromptCursor(false);
                    setWaitBetweenDemos(true);
                    setCurrentDir("Builder");
                    clearTimeout(timeoutRef);
                    }, 1000)
                }}/>
                )}
                {waitBetweenDemos && (
                <span style={{ fontSize: 18 }}>[bobthebuilder@AuditDeploy {currentDir}]$&nbsp;<p class="cursor"></p></span>
                )}
                {showClearPrompt && onDisplayEnd()}
                {/* {showClearPrompt && (
                <Typewriter type="prompt" directory="Builder" text="clear" textDelay={80} fontSize={18} cursor={showClearPromptCursor} onTypingEnd={() => {
                    const timeoutRef = setTimeout(() => {
                    //setShowBuilderInitCmd(false);
                    //setShowBuilderDockerCmd(true);
                    setShowClearPrompt(false);
                    setShowLsPrompt(false);
                    setShowLsOutput(false);
                    //setWaitBeforeBuilderLs(true);
                    setTimer(null);

                    onDisplayEnd();

                    clearTimeout(timeoutRef);
                    }, 1000)
                }}/>
                )} */}
            </div>
            )}
    </div>
  );
};

export default TerminalInitOutput;